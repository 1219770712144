// import { Offcanvas } from 'bootstrap';
import React from 'react';
import {Nav, Navbar, NavItem} from 'react-bootstrap'
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from "styled-components";


const LinkWrapper = styled.div`
  .nav{
    font-family: Poppins;
    background-color: lightblue !important
  }
  ul li{
    display: inline
  }
`;

function Header({handleCardClick}) {
    const history = useHistory();
    const handleLogout = () =>{
        console.log('logout')
        localStorage.clear('Secx')
        history.push('/')
        window.location.reload()
    }
    return (
    <LinkWrapper>
        <Navbar bg="light" className='nav w-100 small' expand='sm'>
            <div className='container-fluid'>
                <Navbar.Brand href="#"><img src={'images/smart1-01.png'} height={'60px'} alt='SECx'/></Navbar.Brand>
                <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <i className='fa fa-bars'></i>
                </button>
                
                <div className="offcanvas offcanvas-end" style={{width: '300px', backgroundColor:'lightblue'}} tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel"><img src={'images/smart1-01.png'} height={'60px'} alt='SECx'/> </h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <Nav>
                            <ul className='list-unstyled'>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3 active" to='#'
                                    onClick={() => handleCardClick(0)}> 
                                         <span className='dash'><i className="fas fa-home-alt mr-4"></i>Dashboard</span>
                                    </NavLink>
                                    <NavLink className="nav-link ml-2 mb-3 active" to='#'
                                    onClick={() => handleCardClick(1)}> 
                                        <span className='dash'><i className="fas fa-user-alt mr-4"></i>My Profile</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3" to='#'
                                    onClick={() => handleCardClick(2)}>
                                        <span >
                                            <i className="fas fa-map-marker-alt mr-4"></i>
                                            Notification
                                            <small className='ml-1'>
                                                <span className="badge badge-danger"
                                                style={{borderRadius: '50%', 
                                                padding: '5px', 
                                                display: 'inline-block',
                                                boxShadow: '0 0 2px #888'}}>
                                                    0
                                                </span>           
                                            </small> 
                                        </span> 
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#' onClick={() => handleCardClick(3)}> 
                                        <span className='dash'><i className="fas fa-chart-pie-alt mr-4"></i>Transaction History</span> 
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#' onClick={() => handleCardClick(4)}> 
                                        <span className='dash'><i className="fas fa-credit-card mr-4"></i>Deposit</span> 
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'  onClick={() => handleCardClick(5)}> 
                                        <span className='dash'><i className="fas fa-credit-card mr-4"></i>Withdraw</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#' onClick={() => handleCardClick(6)}>
                                        <span className='dash'><i className="fas fa-credit-card mr-4"></i>Refer</span> 
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#' onClick={() => handleCardClick(7)}>
                                        <span className='dash'><i className="fas fa-bolt mr-4"></i>How it works</span> 
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#' onClick={handleLogout}>
                                        <span className='dash'><i className="fas fa-sign-out mr-4"></i>Logout</span> 
                                    </NavLink>
                                </NavItem>
                                
                            </ul>
                        </Nav>
                    </div>
                </div>
            </div>
        </Navbar>

        {/* Big screen */}
        <Navbar collapseOnSelect expand="lg" id='mynav'  
        className='nav big-one' variant='dark'>
                <div className="container-fluid">
                    <Navbar.Brand  href="/"><img src={'images/smart1-01.png'} height={'60px'} width={'60px'} alt='SECx'/></Navbar.Brand>
                        <Nav className='align-self-center ml-auto'>
                            <NavItem>
                                <NavLink className="nav-link ml-3 "  to='#' > 
                                    My Dashboard
                                </NavLink>
                            </NavItem>
                        </Nav>
                </div>
            </Navbar>
    </LinkWrapper>
    );
}

export default Header;