import React, {useState, useEffect}  from 'react';
import { Nav, Navbar, NavItem} from 'react-bootstrap';
import {NavLink, useHistory, Link, useLocation} from "react-router-dom";

function Header(props) {
	const history = useHistory()
	const location = useLocation()
	const [user, setUser] = useState(false)
	const [open, setOpen] = useState(false);
	useEffect(()=>{
		setInterval(()=>{
			const isLoggedIn = localStorage.getItem("Secx")
			if(isLoggedIn){
				setUser(true)
			}
			else{
				setUser(false)
			}
		}, 500)
	}, [])

	const closeNav =()=>{
        // var myNav = document.getElementById('responsive-navbar-nav');
        document.getElementById("responsive-navbar-nav").style.height = "0%"
        setOpen(false)
    }
    const openNav =()=>{
        document.getElementById("responsive-navbar-nav").style.height = "100%"
        setOpen(!open)
    }

	const handleLogout = () =>{
        localStorage.clear('Secx')
        history.push('/')
    }
	const handleHow = () => {
        if(location.pathname ==='/'){
            document.getElementById("how-it-work").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
	const handleFeature = () => {
        if(location.pathname ==='/'){
            document.getElementById("feature").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
	const handlePlan = () => {
        if(location.pathname ==='/'){
            document.getElementById("plan").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
	const handleContact = () => {
        if(location.pathname ==='/'){
            document.querySelector("contact-us").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
	const handleHome = () => {
        if(location.pathname ==='/'){
            document.getElementById("home").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
    return (
        <header className="header">
			<Navbar collapseOnSelect expand="lg" id='mynav'  className='nav overlay fixed-top  head' variant='dark'>
                <div className="container">
                    <Navbar.Brand  href="/"><img src={'images/smart1-01.png'} height="110.06px" width="200px" alt='SECX' /></Navbar.Brand>
                        {/* <button arial-controls='responsive-navbar-nav'
                         onClick={openNav}>
                            &#9776;
                        </button> */}
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} 
                        className='toggle'
                        onClick={openNav}>
                        &#9776;
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav" in={open}>
                        <Link to='#' className="closebtn d-md-none mt-1 mb-3" onClick={closeNav}>&times;</Link>
                        <Nav className='align-self-center ml-auto'>
							<NavItem>
                                <NavLink id='home' className="nav-link  mt-2  ml-3 first" to='/'
								onClick={handleHome}> Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link  mt-2 ml-3" to='/' 
								onClick={handleHow}> About Us </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link  mt-2 ml-3" to='/'
								onClick ={handleFeature}> Feature</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link  mt-2 ml-3" to='/'
								onClick={handlePlan}> Plans</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link  mt-2 ml-3" to='/'
								onclick={handleContact}>Contact</NavLink>
                            </NavItem>
                                
                                    {user ? (
                                        <>
                                            <NavItem>
                                                <NavLink className="nav-link mt-2 ml-3 "  to='/dashboard' > 
                                                    Dashboard
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className="nav-link ml-3 "  to='#' 
                                                onClick={handleLogout}> 
                                                    <button className='btn btn-md button'>Logout</button>
                                                </NavLink>
                                            </NavItem>
                                        </>
                                    ):
                                            <NavItem>
                                                <NavLink className="nav-link ml-3 "  to='/login' > 
                                                    <button className='btn btn-md button'>Sign In</button>
                                                </NavLink>
                                            </NavItem>
                                    }
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
		</header>
    );
}

export default Header;