import React from 'react';

function SectionFive(props) {
    return (
        <section className="faq bluish-purple relative overflow-h ptb-100">
            <div className="container relative">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                        <div className="section-heading text-center">
                            <h6 className="sub-title text-uppercase wow fadeInUp">FAQS</h6>
                            <h2 className="title wow fadeInUp">Frequently Asked questions</h2>
                            <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 relative z-index-1 wow fadeInLeft">
                        <div className="faq-img">
                            <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/faq.png" alt="FAQ"/>
                        </div>
                    </div>
                    <div  className="col-xl-6 col-lg-6 col-md-12 relative z-index-1 wow fadeInRight">
                        <div className="faq-content" >
                            <div className="tab-content" id="myTabContent">
                                <div role="tabpanel" className="tab-pane fade in active show" id="general" aria-labelledby="general-tab">
                                    <div className="faq-box">
                                        <div className="faq-title active">What is Smart Earnerx?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How can I make a deposit on the platform?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How do I subscribe to an investment package?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What cryptocurrency can I use to withdraw?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="pre_ico" aria-labelledby="pre_ico-tab">
                                    <div className="faq-box">
                                        <div className="faq-title">How do I benefit from the ICO Token?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What cryptocurrencies can I use to purchase?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What is Ico Crypto?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How do I make payment on the site?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How do I subscribe to a package?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What cryptocurrencies can I use to withdraw?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="tokens" aria-labelledby="tokens-tab">
                                    <div className="faq-box">
                                        <div className="faq-title">What cryptocurrency can I use to withdraw?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How do I benefit from the ICO Token?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What is Smart EarnerX?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">What cryptocurrencies can I use to purchase?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                    <div className="faq-box">
                                        <div className="faq-title">How do I benefit from the ICO Token?</div>
                                        <div className="faq-panel">
                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionFive;