import React, {useState} from 'react';
import {Alert, Spinner} from 'react-bootstrap'
import {Link, useHistory} from "react-router-dom";
import { updateProfile } from '../../../utils/postData';

function Form({data}) {
    const [state, setState]= useState({
        gender: 'Male',
        mobile:'',
        address: '',
        isUpdated: false
    })
    const [signUpError, setsignUpError]= useState('');
    const [signUpSuccess, setsignUpSuccess]= useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    

    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit= async e=>{
        e.preventDefault()
        console.log(state)
        setIsLoading(true)
        try {
            // dispatch({type: 'AUTH', data: {result}})
            const result = await updateProfile(state)
            if(result.data){
                setsignUpSuccess(result.data.message);
                setsignUpError('');
                setState({
                    gender: '',
                    address:'',
                    mobile: '',
                    isUpdated: true
                })
                setIsLoading(false)
            }

            } catch (error) {
                if(error.response){
                    setsignUpError(error.response.data.message)
                    setsignUpSuccess('');
                    setIsLoading(false)
                }
                else{
                    console.log(error)
                } 
            }
    }


    return (
        <div className='pt-4 col-md-6 mx-auto col-12'>
            <form onSubmit={(values) => handleSubmit(values)} className=" needs-validation sign">
                <div className='container'>
                {signUpSuccess && <Alert  variant={'success'} className='pb-3 text-center'>
                        {signUpSuccess} </Alert> }
                    <div className="form-group row mt-4">
                        <div className="col-12">
                            <label>
                                <div>
                                    Investor Name
                                </div>
                            </label>
                            <input type="text"
                                placeholder="Name"  
                                value={data.name} readOnly
                                className="form-control" required
                                />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12">
                            <label>Investor ID</label> 
                            <input type="text" 
                            value = {data.investor_id}
                            readOnly
                            className="form-control" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12 margin">
                            <label className='sd'>Gender</label> 
                            <select name="gender"  value= {data.gender || state.gender}
                                className="form-control option-input" onChange={handleChange}>
                                    <option >Male</option>
                                    <option >Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12 margin">
                            <label className='sd'>Mobile</label>
                            <div className=''> 
                                <input type= 'text' 
                                    value= {data.mobile || state.mobile}
                                    name="mobile"
                                    placeholder='+23481******'
                                    onChange={handleChange} 
                                    className="form-control" required
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12 margin">
                            <label className='sd'>Resident Address</label>
                            <input type="text" name="address"
                            value= {data.address || state.address}
                            placeholder="Resident Address" onChange={handleChange}
                            className="form-control" required
                            />
                        </div>
                    </div>      
                    <div className="form-group text-right  pt-2 ">
                        <button type='submit btn btn-md' disabled= {data.gender && true} 
                        className='btn btn-md w-100 btn-img'>
                            {isLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Submit</>}
                        </button>
                    </div>
                    {signUpError && <Alert  variant={'danger'} className='pb-3 text-center'>
                        {signUpError}
                    </Alert>}
                </div>
        </form>
        </div>
    );
}

export default Form;