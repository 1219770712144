import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline", background: "lightblue" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "lightblue" }}
        onClick={onClick}
      />
    );
  }

  
function SectionFour(props) {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
    const settingsTwo = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToScroll: 1,
      };
    return (
        <section className="plans pt-0 ptb-100 overflow-h">
        <div className="container relative" id='plan'>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                    <div className="section-heading text-center">
                        <h6 className="sub-title text-uppercase wow fadeInUp">Smart EarnerX Feature</h6>
                        <h2 className="title wow fadeInUp">Our Plans</h2>
                        <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                    </div>
                </div>
            </div>

            <div className="row relative big">
                <Slider {...settings}>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Bronze Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>BRONZE</small> </p>
                                        <h5>1500</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                50% ROI in 6 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 1500 to get 2100
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                500 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                    <div className='card mb-3'>
                        <div className='card-body text-center'>
                            <h5>Silver Plan</h5>
                            <div className='card inside text-left mt-4 mb-5'>
                                <div className='card-body'>
                                    <p><small>SILVER</small> </p>
                                    <h5>3000</h5>
                                    <hr/>
                                    <div className='row color mb-3'>
                                        <div className='col-1'>
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div className='col-10'>
                                            50% ROI in 6 days
                                        </div>
                                    </div>
                                    <div className='row color mb-3'>
                                        <div className='col-1'>
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div className='col-10'>
                                            Invest 3000 to get 4500
                                        </div>
                                    </div>
                                    <div className='row color mb-3'>
                                        <div className='col-1'>
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div className='col-10'>
                                            Withdrawal is within 6hrs
                                        </div>
                                    </div>
                                    <div className='row color mb-3'>
                                        <div className='col-1'>
                                            <i class="fas fa-check"></i>
                                        </div>
                                        <div className='col-10'>
                                            1000 Referral bonus
                                        </div>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <Link to='/signup'>
                                            <button className='btn btn-md button'
                                            style={{height: '37px'}}>Sign Up</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Gold Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>GOLD</small> </p>
                                        <h5>5000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                100% ROI in 12 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 5000 to get 10000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                1500 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                    <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Ruby Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>RUBY</small> </p>
                                        <h5>10000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                100% ROI in 12 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 10000 to get 20000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                2000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                    <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Platinum Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>PLATINUM</small> </p>
                                        <h5>20000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                120% ROI in 26 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 20000 to get 44000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                3000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                    <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Francium Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>FRANCIUM</small> </p>
                                        <h5>50000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                120% ROI in 26 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 50000 to get 110000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                5000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>


            <div className="row relative small">
                <Slider {...settingsTwo}>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Bronze Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>BRONZE</small> </p>
                                        <h5>1500</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                50% ROI in 6 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 1500 to get 2100
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                500 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Silver Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>SILVER</small> </p>
                                        <h5>3000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                50% ROI in 6 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 3000 to get 4500
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                1000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Gold Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>GOLD</small> </p>
                                        <h5>5000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                100% ROI in 12 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 5000 to get 10000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                1500 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Ruby Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>RUBY</small> </p>
                                        <h5>10000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                100% ROI in 12 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 10000 to get 20000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                2000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Platinum Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>PLATINUM</small> </p>
                                        <h5>20000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                120% ROI in 26 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 20000 to get 44000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                3000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 feature-box wow">
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Francium Plan</h5>
                                <div className='card inside text-left mt-4 mb-5'>
                                    <div className='card-body'>
                                        <p><small>FRANCIUM</small> </p>
                                        <h5>50000</h5>
                                        <hr/>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                120% ROI in 26 days
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Invest 50000 to get 110000
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                Withdrawal is within 6hrs
                                            </div>
                                        </div>
                                        <div className='row color mb-3'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                                5000 Referral bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-md button'
                                                style={{height: '37px'}}>Sign Up</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    </section>
    );
}

export default SectionFour;