import React, {useEffect} from 'react';
import {Switch, Route, Redirect, Link} from 'react-router-dom';
import FourZeroThree from '../../utils/FourZeroThree';
import NavbarAdmin from '../admin/Navbar';
import AdminSubscription from '../admin/pages/AdminSubscription';
import AdminWallet from '../admin/pages/AdminWallet';
import AdminReferral from '../admin/pages/AdminReferral';
import AllUser from '../admin/pages/AllUser';
import Dash from '../admin/pages/Dash';
import PendingWithdrawal from '../admin/pages/PendingWithdrawal';
import Login from '../Auth/Login';
import Signup from '../Auth/Signup';
import Dashboard from '../Dashboard';
import Home from '../Homepage';
import Profile from '../profile';
import TotalWithdrawal from '../admin/pages/TotalWithdrawal';
import CreateAdmin from '../admin/pages/CreateAdmin';
import ReceiveNotification from '../admin/pages/ReceiveNotification';
import CreditUser from '../admin/pages/CreditUser';


export default function MainComponent() {
    return (
        <div>
            <Switch>
                <Route exact path='/'> 
                    <Home/>
                </Route>
                <Route exact path='/signup'> 
                    <Signup/>
                </Route>
                <Route exact path='/login'> 
                    <Login/>
                </Route>
                <Route exact path='/dashboard'> 
                    <Dashboard/>
                </Route>
                <Route exact path='/profile'> 
                    <Profile/>
                </Route>

                {/* Admin */}
                <Route path='/admin/dashboard'>
                    <NavbarAdmin/>
                    <Dash/>
                </Route>
                <Route path='/admin/user/all'>
                    <NavbarAdmin/>
                    <AllUser/>
                </Route>
                <Route path='/admin/subscription/all'>
                    <NavbarAdmin/>
                    <AdminSubscription/>
                </Route>
                <Route path='/admin/pendingfund'>
                    <NavbarAdmin/>
                    <PendingWithdrawal/>
                </Route>
                <Route path='/admin/wallet'>
                    <NavbarAdmin/>
                    <AdminWallet/>
                </Route>
                <Route path='/admin/ref'>
                    <NavbarAdmin/>
                    <AdminReferral/>
                </Route>
                <Route path='/admin/create_admin'>
                    <NavbarAdmin/>
                    <CreateAdmin/>
                </Route>
                <Route path='/admin/pay/history'>
                    <NavbarAdmin/>
                    <TotalWithdrawal/>
                </Route>
                <Route path='/admin/incoming/notification'>
                    <NavbarAdmin/>
                    <ReceiveNotification/>
                </Route>
                <Route path='/admin/credit_user'>
                    <NavbarAdmin/>
                    <CreditUser/>
                </Route>
                <Route path='/403'>
                    <FourZeroThree/>
                </Route>
                <Redirect to='/'/>
            </Switch>
        </div>
    )
}