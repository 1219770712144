import React from 'react';

function Notification(props) {
    return (
        <div className='col-12 col-md-9'>
            <h2>No Notification</h2>
        </div>
    );
}

export default Notification;