import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom'


export default function AdminReferral() {
    const [wallet, setWallet]= useState([]);
    const [message]= useState('');
    useEffect(() => {
        // axios.get('/admin/job')
        const base_url = 'https://aqueous-plateau-25203.herokuapp.com';
        async function myAPI(){
            try {
                const third = await axios.get(`${base_url}/api/admin/wallet`)
                setWallet(third.data.data)
            } catch (error) {
                console.log(error.response)
            }
        }
        myAPI()
        
        
    }, [])
    
    const tBody=  wallet.map((req)=>{
        return(
                <tr key={req._id}> 
                    <td>{req._id}</td>
                    <td>{req.user}</td>
                    <td>{req.balance}</td>
                    <td>{req.createdAt}</td>
                    <td>{req.updatedAt}</td>
                </tr>
            
        )
    })
    return (
        <div className='container-fluid'>
            <div className='breadcrumb'>
                <small className='breadcrumb-item'> <Link to='/admin/dashboard'>Dashboard</Link> </small>
                <small className='breadcrumb-item'>Wallet</small>
            </div>
            <div className='row mt-5'>
                {wallet.length ? (
                    <>
                        <h6 className='text-center'>{message}</h6>
                        <div className='col-12 mx-auto'>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>User</th>
                                        <th>Balance</th>
                                        <th>Created_at</th>
                                        <th>Updated_at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tBody}
                                </tbody>
                            </table>
                        </div>
                    </>
                ): 
                    <h4>There are no record available</h4>
                }
            </div>
        </div>
    )
}
