import React, {useState, useEffect} from 'react';
import {Alert, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { creditUser } from '../../../utils/postData';

function CreditUser(props) {
    const [state,setState] = useState({
        user: '',
        amount: '1500'
    })
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name] : e.target.value,
        })
    }
    
    const handleSubmit = async(e)=>{
        e.preventDefault();
        setIsLoading(true)
        const formData = state
        try{
            const result = await creditUser(formData)
            setMessage(result.data.message)
            setState({user: ''})
            setIsLoading(false)
        }
        catch(err){
            setIsLoading(false)
            if(err.response){
                setMessage(err.response.data.message)
                console.log(err.response.data.message)
            }
        }
    }
    return (
        <div className='container'>
            <div className='breadcrumb'>
                    <small className='breadcrumb-item'> <Link to='/admin/dashboard'>Dashboard</Link> </small>
                    <small className='breadcrumb-item'>Credit User</small>
                </div>
            <div className='row mt-5'>
                <div className='col-12 col-md-8 mx-auto'>
                    {message && <Alert variant='success' className='text-center'>{message}</Alert>}
                    <form onSubmit={(val) => handleSubmit(val)}>
                        <div className="form-group col-12">                    
                            <input type="text" className='form-control' name="user"
                            placeholder='Enter the user Id'
                            value={state.user}
                            onChange={handleChange} 
                            required/>
                        </div>
                        <div className="form-group col-12">
                            <select className="form-control w-100 option-input" 
                            name="amount" required
                            onChange={handleChange}>
                                <option>1500</option>
                                <option>3000</option>
                                <option>5000</option>
                                <option>10000</option>
                                <option>20000</option>
                                <option>50000</option>
                            </select>
                        </div>
                        <div className='form-group col-12'>
                            <button className='btn btn-primary w-100'>
                            {isLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Send</>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreditUser;