import React from 'react';
import { Link } from 'react-router-dom';

function SectionSix(props) {
    return (
        <footer className="footer purple relative pt-100 mb-5" id="footer">
			<canvas id="footer-dots"></canvas>
			<div className="container contact-us">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12">
						<div className="section-heading text-center">
							<h6 className="sub-title text-uppercase wow fadeInUp">CONTACT</h6>
							<h2 className="title wow fadeInUp">Get In Touch</h2>
							<p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
						</div>
					</div>
					<div className="col-xl-12 col-lg-12 col-md-12">
						<ul className="contact-link wow fadeInUp">
							<li>
								<span className="contact-icon"><img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/mail.png" alt="Mail Icon"/></span>
								<a href="Smartearnerx@gmail.com">Send us a mail</a>
							</li>
							<li>
								<span className="contact-icon"><img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/phone.png" alt="Phone Icon"/></span>
								<a href="tel:+681234567890">*************</a>
							</li>
							<li>
								<span className="contact-icon"><img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/telegram.png" alt="Telegram Icon"/></span>
								<a href="https://t.me/+XU2iGrW53l00ZmM0">Join us on Telegram</a>
							</li>
						</ul>
					</div>
					
				</div>
			</div>
        </footer>
    );
}

export default SectionSix;