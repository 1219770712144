import React, { useState, useEffect } from 'react';
//import {getAll} from '../../../utils/postData';
import axios from "axios";

import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Styles = styled.div`
    .card-footer{
        height: 23px;
        padding-top: 0px;
    }
    a{
        text-decoration:none;
    }
    h3{
        font-weight: bold;
        font-size: 30px
    }
    p{
        font-family: Manrope;
    }
    `

export default function Dash() {
    // Job
    const [data, setData]= useState([]);
    const [allJob, setAllJob]= useState([]);
    const [pendingFund, setPendingFund]= useState([]);
    const [subscribe, setSubscribe] = useState([])
    const [ref, setRef]= useState([]);
    const [wallet, setWallet]= useState([]);
    const [history, setHistory]= useState([]);
    const [reject, setReject]= useState([]);
    
    const History = useHistory()
    // User
    const [allUser, setAllUser]= useState([]);
    useEffect(() => {
        // axios.get('/admin/job')
        const base_url = 'https://aqueous-plateau-25203.herokuapp.com';
        async function myAPI(){
            try {
                const first = await axios.get(`${base_url}/api/admin/all_user`)
                setAllUser(first.data.data);
                const third = await axios.get(`${base_url}/api/admin/pay_request`)
                setPendingFund(third.data.data)
                const fourth = await axios.get(`${base_url}/api/admin/all_subscription`)
                setSubscribe(fourth.data.data)
                const second = await axios.get(`${base_url}/api/admin/ref`)
                setRef(second.data.data)
                const fifth = await axios.get(`${base_url}/api/admin/wallet`)
                setWallet(fifth.data.data)
                const six = await axios.get(`${base_url}/api/admin/pay_history`)
                setHistory(six.data.data)
            } catch (error) {
                switch (error.response.status){
                    case 403:
                        History.push('/403')
                }
            }
        }
        myAPI()
        
        
    }, [])
    const newJob = allJob.filter((ne)=> ne.status==="Pending")
    return (
        <div className='wrapper'>
            <Styles>
            <div className="container-fluid">
                        <h5>Dashboard</h5><hr/>
                        <div className="row">
                            <div className="col-md-3 col-12 mt-3">
                                <div className="card bg-primary">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{allUser.length}</h3>
                                                <p className='down text-white'>All User</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/user/all'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mt-3">
                                <div className="card bg-info">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{subscribe.length}</h3>
                                                <p className='down text-white'>All Subscription</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/subscription/all'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-12 mt-3 col-md-3 ">
                                <div className="card bg-warning">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{pendingFund.length}</h3>
                                                <p className='down text-white'>Withdrawal Request</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/pendingfund'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mt-3">
                                <div className="card bg-success">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{history.length}</h3>
                                                <p className='down text-white'>Payment History</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/pay/history'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-3 col-12 mt-3">
                                <div className="card bg-info">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{ref.length}</h3>
                                                <p className='down text-white'>All Refferal</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/ref'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-12 mt-3">
                                <div className="card bg-danger">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{wallet.length}</h3>
                                                <p className='down text-white'>Wallet</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/wallet'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </Styles>
        </div>
    )
}
