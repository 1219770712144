import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom'


export default function AdminReferral() {
    const [ref, setRef]= useState([]);
    const [message, setMessage]= useState('');
    const[q, setQ] = useState('');
    useEffect(() => {
        // axios.get('/admin/job')
        const base_url = 'https://aqueous-plateau-25203.herokuapp.com';
        async function myAPI(){
            try {
                const third = await axios.get(`${base_url}/api/admin/ref`)
                setRef(third.data.data)
            } catch (error) {
                console.log(error.response)
            }
        }
        myAPI()
        
        
    }, [])
    
    const tBody=  ref.filter(d=> {
            console.log(Object.keys(d))
            return Object.keys(d).some(key=>d['user'].toString().toLowerCase().indexOf(q.toString().toLowerCase())> -1)
        }).map((req)=>{
        return(
                <tr key={req._id}> 
                    <td>{req.user}</td>
                    <td>{req.referred}</td>
                    <td>{req.createdAt}</td>
                    <td>{req.updatedAt}</td>
                </tr>
            
        )
    })
    return (
        <div className='container'>
            <div className='breadcrumb'>
                <small className='breadcrumb-item'> <Link to='/admin/dashboard'>Dashboard</Link> </small>
                <small className='breadcrumb-item'>Referral</small>
            </div>
            <div className='row text-right mt-2'>
                <div className=' offset-md-9 col-md-3 col-12 form-group'>
                    <input type='text' className='form-control search-box' value={q}
                    onChange={e=> setQ(e.target.value)} placeholder="Search by user"/>
                </div>
            </div>
            <div className='row mt-5'>
                {ref.length ? (
                    <>
                        <h6 className='text-center'>{message}</h6>
                        <div className='col-12 col-md-10 mx-auto'>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Referred</th>
                                        <th>Created_at</th>
                                        <th>Updated_at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tBody}
                                </tbody>
                            </table>
                        </div>
                    </>
                ): 
                    <h4>There are no record available</h4>
                }
            </div>
        </div>
    )
}
