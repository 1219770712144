import React, {useState, useEffect} from 'react';
import { signUp } from '../../utils/postData';
import {Alert, Spinner} from 'react-bootstrap'
import {Link, useHistory} from "react-router-dom";
import jwtDecode from 'jwt-decode'
// import Header from '../Header'; 

export default function Signup(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [state, setState]= useState({
        password:'',
        referral: props.referral || '',
        // account_type: "Admin",
        name: '',
        email: '',
        repeat_password:''
    })
    const history = useHistory();
    useEffect(()=>{
        // localStorage.clear('Secx')
        /* global google */

    }, [])
    
    const handleClick = () =>{
        window.google.accounts.id.initialize({
            client_id: '448779916170-7htguqmkbmeutget3lbmn4sl0gssht75',
            callback: handleCallback
        });
        window.google.accounts.id.prompt(notification =>{
            if(notification.isNotDisplayed()){
                throw new Error('Clear your cookies')
            }
        })
        window.google.accounts.id.renderButton(
            document.getElementById('signInDiv'),
            {theme: "outline", size: "large"}
        )
    }
    const handleCallback = (res)=>{
        const token = res.credential
        const decodeToken = jwtDecode(token)
        console.log(decodeToken)
    }
    const [signUpError, setsignUpError]= useState('');
    const [signUpSuccess, setsignUpSuccess]= useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const togglePassword =(e)=>{
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setShowPassword(!showPassword)
      }
    const responseGoogle = async(response) => {
        console.log(response);
    }

    const handleSubmit= async e=>{
        e.preventDefault()
        setIsLoading(true)
        try {
            // dispatch({type: 'AUTH', data: {result}})
            const result = await signUp(state)
            if(result.data){
                setsignUpError('');
                setState({
                    password:'',
                    name: '',
                    email: '',
                    repeat_password:''
                })
                setIsLoading(false)
                localStorage.setItem("Secx", JSON.stringify(result.data))
                history.push('dashboard')  
            }

            } catch (error) {
                if(error.response){
                    setsignUpError(error.response.data.message)
                    setsignUpSuccess('');
                    setIsLoading(false)
                }
                else{
                    console.log(error)
                } 
            }
 }
    return (
        <section className='signup-border'>
                <div className=' container'>
                    <div className= 'row '>
                        <div className='col-12 col-md-6 mx-auto mt-4 mb-5 pb-5 signup-border'>
                            <div className="text-center mb-4">
                                <div className='mb-1 mt-4'>
                                    <img src={'images/logo.png'} height="160" width="140" alt='secx' />
                                </div>
                                <h4  className='mb-2'><b>Sign Up</b></h4>
                                <div className='w-60'>
                                    {signUpSuccess && 
                                    <Alert  variant={'success'} className='pb-3 text-center'>
                                        {signUpSuccess}
                                    </Alert>}
                                </div>
                            </div>
                            <div>
                                <form onSubmit={(values) => handleSubmit(values)} className=" needs-validation sign">
                                        <div className='container'>
                                            {/* <div className="form-group row pt-3 pb-2 mt-5">
                                                <div className="col-11 mx-auto">
                                                    <button
                                                        onClick={handleClick}
                                                        className='w-100'
                                                        style={{height: '40', cursor:'pointer'}}
                                                    >
                                                        <i className='fa fa-google'></i>
                                                        <span style={{fontSize: '22px'}}> Login with Google</span>
                                                    </button>
                                                    <div className="signInDiv"></div>
                                                </div>
                                            </div> */}
                                            <div className="form-group row mt-4">
                                                <div className="col-12 ">
                                                    <label>
                                                        <div>
                                                            Name
                                                        </div>
                                                    </label>
                                                    <input type="text" id="firstname" name="name"
                                                        placeholder="Fullname"   onChange={handleChange}
                                                        value={state.name}
                                                        className="form-control" required
                                                        />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12">
                                                    <label>Email</label> 
                                                    <input type="text"  id="email" name="email"
                                                    placeholder="Email" onChange={handleChange}
                                                    value={state.email}
                                                    className="form-control" required/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 margin">
                                                    <label className='sd'>Password</label>
                                                    <div className='input-group'> 
                                                        <input type = {showPassword ? "text" : "password"} 
                                                            name="password"
                                                            onChange={handleChange} style={{borderRight:'0px'}}
                                                            value={state.password}
                                                            className="form-control" required
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 margin">
                                                    <label className='sd'>Confirm Password</label>
                                                    <input type="password"  id="re_pass" name="repeat_password"
                                                    placeholder="Confirm Password" onChange={handleChange}
                                                    value={state.repeat_password}
                                                    className="form-control" required
                                                    />
                                                </div>
                                            </div>
                                            <div className='row text-center'>
                                                <div className='mx-auto col-12'>
                                                        <input type='checkbox' required/> <small>I have read and agree to <Link to='#'>Terms of privacy</Link> </small>
                                                </div>
                                            </div>
                                                        
                                            <div className="form-group text-right  pt-2 ">
                                                <button type='submit' className='btn btn-md w-100 button'>
                                                    {isLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Submit</>}
                                                </button>
                                            </div>
                                            <hr/>
                                            <div className=' text-center col-12 mb-2'>
                                                <small>Already have an account? <Link to='login'>Login</Link> </small>
                                            </div>
                                            {signUpError && <Alert  variant={'danger'} className='pb-3 text-center'>
                                                {signUpError}
                                            </Alert>}
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {props.referral && <Footer/>} */}
           </section>
    )
}

