import React, {useState} from 'react';
import {Alert} from 'react-bootstrap';
import axios from 'axios';
import { internalTransfer, withdraw } from '../../../utils/postData';


const InternalTransfer = ({balance})=>{
    const [state, setState] = useState({
        balance,
        investor_id:'',
        amount: '1000'
    })

    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name] : e.target.value,
        })
    }
    const handleTransfer = async(e)=>{
        e.preventDefault()
    //    internalTransfer
       try{
        const formData = state
        console.log(formData)
        const result = await internalTransfer(formData)
        console.log(result.data.message)
        window.location.reload()
       }
       catch(err){
        if(err.response){
            console.log(err.response.data.message)
        }
        else{
            console.log(err)
        }
       }
    }
    return(
        <div className='row mt-5 down'>
            <div className='col-md-7 col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={(val) => handleTransfer(val)}>
                            <div className='form-group'>
                                <label>
                                    <div>Available balance</div>
                                </label>
                                <input className='form-control' onChange={handleChange} 
                                type='text' name='balance'
                                value= {`${balance} SECx`}
                                readOnly={true}/>
                            </div>
                            <div className='form-group'>
                                <label>
                                    <div>Investor_id</div>
                                </label>
                                <input className='form-control' onChange={handleChange} 
                                type='text' name='investor_id' required/>
                            </div>
                            <div className='form-group'>
                                <label>
                                    <div>Amount to be transferred</div>
                                </label>
                                <select name="amount"  
                                    className="form-control 
                                    option-input" onChange={handleChange} >
                                        <option >1000</option>
                                        <option >1500</option>
                                        <option >3000</option>
                                        <option >5000</option>
                                        <option >10000</option>
                                        <option >20000</option>
                                        <option >50000</option>
                                </select>
                            </div>
                            
                            <div className='form-group'>
                                <button className='button w-100'>Transfer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const UsdtWithdrawal = ({balance})=>{
    const [state, setState] = useState({
        balance,
        usdt_address:'',
        amount: '1000'
    })
    const [message, setMessage] = useState('')

    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name] : e.target.value,
        })
    }
    const handleWithdraw = async(e)=>{
        e.preventDefault()
        const formData = state
           try{
            const result = await withdraw(formData)
            // console.log(result.data)
            setState({usdt_address: ''})
            setMessage(result.data.message)
           }
           catch(err){
            if(err.response){
                console.log(err.response)
                setMessage(err.response.data.message)
            }
            else{
                console.log(err)
            }
           }
    }
    return(
        <div className='row mt-5 down'>
            <div className='col-md-7 col-12'>
                {message && <Alert variant='success text-center'>{message}</Alert>}
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={(val)=>handleWithdraw(val)}>
                            <div className='form-group'>
                                <label>
                                    <div>Available balance</div>
                                </label>
                                <input className='form-control' onChange={handleChange} 
                                type='text' name='balance'
                                value= {`${balance} SECx`}
                                readOnly={true}/>
                            </div>
                            <div className='form-group'>
                                <label>
                                    <div>Usdt address</div>
                                </label>
                                <input className='form-control' 
                                onChange={handleChange} 
                                value={state.usdt_address}
                                type='text' name='usdt_address' required/>
                            </div>
                            <div className='form-group'>
                                <label>
                                    <div>Amount</div>
                                </label>
                                <select name="amount"  
                                className="form-control option-input" 
                                onChange={handleChange} >
                                    <option >1000</option>
                                    <option >1500</option>
                                    <option >3000</option>
                                    <option >5000</option>
                                    <option >10000</option>
                                    <option >20000</option>
                                    <option >50000</option>
                            </select>
                            </div>
                            <div className='form-group'>
                                <button className='button w-100 btn-md'>Withdraw</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Withdrawal({data}) {
    const [transfer, setTransfer] = useState(false)
    const [withdraw, setWithdraw] = useState(false)
    const handleClick=(param)=>{
        if(param === 1){
            setTransfer(true);
            setWithdraw(false);
        }
        else{
            setWithdraw(true);
            setTransfer(false);
        }
    }
    return (
        <>
            <div className='col-12 col-md-9'>
                <Alert variant={'danger'} className='pb-3 text-center'> 
                    You must have an equivalent running investment of the withdrawal amount before you can place a withdrawal
                </Alert>
                <div className='card mt-5' onClick={()=> handleClick(1)}>
                    <div className='card-header'>
                        <h5 className='card-text'>
                        <i class="fas fa-users"></i> Internal Transfer
                        </h5>
                    </div>
                </div>
                <div className='card mt-2' onClick={() => handleClick (2)}>
                    <div className='card-header'>
                        <h5 className='card-text'>
                        <i class="fad fa-envelope-open-dollar"></i> Withdraw with usdt <small><i>(bep20)</i></small>
                        </h5>
                    </div>
                </div>
                {transfer && <InternalTransfer balance = {data.balance}/>}
                {withdraw && <UsdtWithdrawal balance = {data.balance}/> }
            </div>
        </>
    );
}

export default Withdrawal;