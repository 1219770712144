import React, {useState,useEffect} from 'react';
import { notify, ref } from '../../../utils/postData';
import {Alert, Spinner} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Referral({data}) {
    const[state, setState] = useState({investor_id: ''})
    const[notification, setNotification] = useState('')
    const[account_type] = useState('user')
    const[message, setMessage] = useState('')
    const[notifyMessage, setNotifyMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isTextLoading, setIsTextLoading] = useState(false);

    useEffect(()=>{
        document.getElementById('hide-textArea').style.display = 'none'
    }, [])

    const handleText= ()=>{
        document.getElementById('hide-textArea').style.display = 'inline'
    }
    const handleChange = (e)=>{
        setState({
            [e.target.name]: e.target.value
        })
    }
    const handleTextArea = (e)=>{
        setNotification({
            [e.target.name]: e.target.value
        })
    }
    const handleTextSubmit =async(e)=>{
        e.preventDefault();
        setIsTextLoading(true)
        const formData = {notification: notification.notification, account_type}
        try{
            const result = await notify(formData)
            setIsTextLoading(false)
            console.log(result.data)
            setNotifyMessage(result.data.message)
            setNotification({notification: ''})
        }
        catch(err){
            setIsTextLoading(false)
        }
    }


    const handleSubmit =async(e)=>{
        e.preventDefault();
        setIsLoading(true)
        const formData = state
        try{
            const result = await ref(formData)
            setIsLoading(false)
            console.log(result.data)
            setMessage(result.data.message.message)
        }
        catch(err){
            setIsLoading(false)
        }
    }
    return (
        <div className='col-12 col-md-9 mt-5'>
            <h3>Extra referral bonus for our investors</h3>
            <ul>
                <li>Invite <span>two</span> investors the same day you make your first investment and earn 1,500SECx</li>
                <li>Invite <span>ten</span> investors within the first two hours you make your first investment and earn 5000SECx</li>
                <li>Invite <span>twenty</span> investors the same day you make your first investment and earn 10,000SECx</li>
                <li>Invite <span>thirty one</span> investors any day till your investment runs out and earn 10,000SECx</li>
                <li>Invite <span>three</span> investors to invest on the titanium package and earn 20,000SECx</li>
                <li>Invite <span>thirty five</span> investors any day till your investment runs out and earn 30,000SECx</li>
                <li>Invite <span>one hundred and five</span> investors any day till your investment runs out and earn 50,000SECx</li>
            </ul>
            <div className='row'>
                <div className='col-md-6 col-12'>
                    <h6>Due for extra referral bonus? <Link to='#' onClick={handleText}>contact admin</Link></h6>
                    {notifyMessage && <Alert variant='success' className='text-center'>{notifyMessage}</Alert>}
                    <div id="hide-textArea">
                        <form onSubmit = {(val) => handleTextSubmit(val)}>
                            <textarea 
                            className='form-control' 
                            rows={5} name='notification'
                            value= {notification.notification}
                            onChange= {handleTextArea} required></textarea> <br/>
                            <button className='btn btn-md w-100 btn-primary'>
                                {isTextLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Send</>}
                            </button> <br/><br/>
                        </form>
                    </div>
                    <small >Who referred you?</small>

                    <form className='pt-3' onSubmit={(val)=>handleSubmit(val)}>
                        {message && <Alert variant='success' className='text-center'>{message}</Alert>}
                        <div className='form-group'>
                            <input type='text' 
                            onChange={handleChange}
                            className='form-control' 
                            placeholder="Investor's_id"
                            name= 'investor_id'/>
                        </div>
                        <div className='form-group'>
                            <button className='button w-100'>
                                {isLoading ? <Spinner variant='light' animation="border" role="status"/>: <>Submit</>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <h4>Your investor_id: {data.investor_id}</h4>
        </div>
    );
}

export default Referral;