import React from 'react';
import Typical from 'react-typical';
function SectionOne(props) {
    return (
        <main class="main" >
			<section class="home-banner relative overflow-h" >
				<div class="container" id='home'>
					<div class="row">
						<div class="col-xl-6 col-lg-6 col-md-12 d-flex flex-center">
							<div class="banner-content relative z-index-1">
								<h1 class="banner-title wow fadeInLeft">
									Invest In 
									<span> 
										<Typical
											steps={[' Smart EarnerX', 300]}
											loop={Infinity}
											wrapper='span'
										/>
									</span> 
									
									Way To Trade
								</h1>
								<p class="wow fadeInLeft">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem</p>
								
							</div>
						</div>
						<div class="col-xl-6 col-lg-6 col-md-12 wow fadeInUp">
							<div class="banner-img">
								<img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/banner-img.png" alt="Banner Image"/>
							</div>
						</div>
					</div>
				</div>
			</section>
        </main>
    );
}

export default SectionOne;