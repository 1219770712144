// import { Offcanvas } from 'bootstrap';
import React, {useState, useEffect} from 'react';
import {Nav, Navbar, NavItem} from 'react-bootstrap'
import axios from 'axios';
import * as aiIcon from 'react-icons/ai'
import { Link, NavLink } from 'react-router-dom';
import styled from "styled-components";


const LinkWrapper = styled.div`
  .nav{
    font-family: Poppins;
    background-color: #403ab8 !important
  }
  ul li{
    display: inline
  }
`;

function NavbarAdmin(props) {
    const [data, setData] = useState([])
    useEffect(() => {
        const base_url = 'https://aqueous-plateau-25203.herokuapp.com';
        async function myAPI(){
            try {
                const res = await axios.get(`${base_url}/api/admin/notification`)
                setData(res.data.data)
            } catch (error) {
                switch (error.response.status){
                    case 403:
                        History.push('/403')
                    break;
                    default:
                    console.log(error)
                }
            }
        }
        myAPI()
    }, []);
    return (
    <LinkWrapper>
        <Navbar bg="light" className='nav w-100' expand='sm'>
            <div className='container-fluid'>
                <button className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <i className='fa fa-bars'></i>
                </button>
                <Navbar.Brand href="#">Welcome Admin </Navbar.Brand>
                <div className="offcanvas offcanvas-end" style={{width: '300px', backgroundColor:'lightblue'}} tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-NavbarAdmin">
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <Nav>
                            <ul className='list-unstyled'>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3 active" to='/admin/dashboard'> 
                                        <aiIcon.AiFillHome/><span className="ml-2">Dashboard </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3" to='/admin/incoming/notification'>
                                        <span>
                                        <i class="fas fa-map-marker-alt mr-4"></i>
                                            Notification
                                            <small className='ml-1'>
                                                <span class="badge badge-danger"
                                                style={{borderRadius: '50%', 
                                                padding: '5px', 
                                                display: 'inline-block',
                                                boxShadow: '0 0 2px #888'}}>
                                                    {data.length}
                                                </span>           
                                            </small>
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'> Send General Message</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='/admin/create_admin'> Make Admin</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'>Delete Account</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='/admin/credit_user'>Credit User</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'>How it works</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'>Frequently asked questions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'>Contact Us</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="nav-link ml-2 mb-3"  to='#'>Terms and Policy</NavLink>
                                </NavItem>
                            </ul>
                        </Nav>
                    </div>
                </div>
            </div>
        </Navbar>
    </LinkWrapper>
    );
}

export default NavbarAdmin;