import React, {useState, useEffect} from 'react';
import axios from 'axios'
import Header from './pages/Header';
import MainContent from './pages/MainContent';
import Sidebar from './pages/Sidebar';
import styled from "styled-components";
import Profile from '../profile';
import Notification from './pages/Notification';
import TransactionHistory from './pages/TransactionHistory';
import Deposit from './pages/Deposit';
import CardPayment from './pages/CardPayment';
import Withdrawal from './pages/Withdrawal';
import Referral from './pages/Referral';
import About from './pages/About';

const LinkWrapper = styled.div`
  .card-one{
    background-image: linear-gradient(to right, lightblue , #FF6957); color: #fff;
  }
  .ev{
    background-image: url('/images/banner-pattern.png') !important;
    backround-position: right;
    background-size: cover
  }
  .card-one p{
    margin-top: 16px;
    font-size: 17px
  }
  .big{
    display: inline;
    font-family: 'Manrope'
  }
  
  .small{
    display: none !important
  }
  .big .card{
    border-radius: 0px;
    border-top: 0px;
    border-bottom: 0px;
    cursor: pointer
  }
  .big .card:hover{
    background-color: #403ab8 !important;
    color: white
  }
  input{
    background-color: floralwhite;
    border-radius: 30px;
    color: rgb(190, 190, 243) !important
  }
  .option-input{
    background-color: floralwhite;
    border-radius: 30px;
    color: rgb(190, 190, 243) !important
  }
  .btn-img{
    height: 50px;
    background-image: linear-gradient(to right, #3326b1 , #FF6957); color: #fff !important;
  }
  @media (max-width: 768px) {
    .card-one h2{
        font-size: 17px
      }
    .card-one p{
        font-size: 12px
      }
    .big{
        display: none !important
    }
    .big-one{
        display: none !important
    }
    .pay .ml-3{
      margin-left: 0px !important
    }
    .small{
        display: inline-block !important
      }
 }
`;

function Dashboard(props) {
    const [step, setStep] = useState(0)
    const [data, setData] = useState({})
    const [active, setActive] = useState('')
    useEffect(()=>{
      const base_url = 'https://aqueous-plateau-25203.herokuapp.com'
      axios.get(`${base_url}/api/user/profile`).then(res=>{
        setData(res.data.data)
      })
    }, [data])
    const handleCardClick = (state)=>{
        setStep(state)
    }
    const renderSwitch = (param) => {
        switch (param) {
          case 0: 
          return(
            <MainContent data={data}/>
          )
            
          case 1: 
            return(
              <Profile data={data}/>
              )
            
          case 2: 
            return(
              <Notification/>
              )
          case 3: 
            return(
              <TransactionHistory/>
              )
          case 4: 
            return(
              <Deposit 
              handleCardClick={handleCardClick} data={data}/>
              )
          case 5: 
            return(
              <Withdrawal 
              handleCardClick={handleCardClick} data={data}/>
              )
          case 6: 
            return(
              <Referral 
              handleCardClick={handleCardClick} data={data}/>
              )
          case 7: 
            return(
              <About data={data}/>
              )
          case 10: 
            return(
              <CardPayment data={data}/>
              )
          
          default:
              return(
            <MainContent data={data}/>
          )
              break;
          }
      }


    return (
        <LinkWrapper>
            <Header handleCardClick = {handleCardClick}/>
            <div className='container-fluid ev'>
                <div className='row'>
                    <Sidebar handleCardClick = {handleCardClick}/>
                    {renderSwitch(step)}
                </div>
            </div>
        </LinkWrapper>
    );
}

export default Dashboard;