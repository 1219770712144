import React from 'react';
import {useHistory} from "react-router-dom";



function Sidebar({handleCardClick}) {
    const history = useHistory();
    const handleLogout = () =>{
        localStorage.clear('Secx')
        history.push('/')
    }
    return (
            <div className='col-md-2 pl-0 big'>
                <ul className='list-unstyled top'>
                    <li className='default'>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(0)}>
                            <span className='dash'><i className="fas fa-home-alt mr-4"></i>Dashboard</span>
                        </div>
                    </li>
                    <li className='default'>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(1)}>
                            <span className='dash'><i className="fas fa-user-alt mr-4"></i>My Profile</span>
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(2)}>
                            <span >
                                <i className="fas fa-map-marker-alt mr-4"></i>
                                Notification
                                <small className='ml-1'>
                                    <span className="badge badge-danger"
                                    style={{borderRadius: '50%', 
                                    padding: '5px', 
                                    display: 'inline-block',
                                    boxShadow: '0 0 2px #888'}}>
                                        0
                                    </span>           
                                </small> 
                            </span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(3)}>
                            <span className='dash'><i className="fas fa-chart-pie-alt mr-4"></i>Transaction History</span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(4)}>
                            <span className='dash'><i className="fas fa-credit-card mr-4"></i>Deposit</span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(5)}>
                            <span className='dash'><i className="fas fa-credit-card mr-4"></i>Withdraw</span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(6)}>
                            <span className='dash'><i className="fas fa-credit-card mr-4"></i>Refer</span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`} onClick={() => handleCardClick(7)}>
                            <span className='dash'><i className="fas fa-bolt mr-4"></i>How it works</span> 
                        </div>
                    </li>
                    <li className=''>
                        <div className={`card pl-3 pt-4 pb-4 active`}onClick={handleLogout} >
                            <span className='dash'><i className="fas fa-sign-out mr-4"></i>Logout</span>
                        </div>
                    </li>
                </ul>
                
            </div>
    );
}

export default Sidebar;