import React from 'react';

function SectionThree(props) {
    return (
        <section className="features bluish-purple ptb-100 overflow-h">
            <div className="container relative" id='feature'>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                        <div className="section-heading text-center">
                            <h6 className="sub-title text-uppercase wow fadeInUp">Cryptorius Feature</h6>
                            <h2 className="title wow fadeInUp">Best Features</h2>
                            <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                </div>
                <div className="row relative z-index-1">
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-1.png" className="feature-icon mb-30" alt="Currency Wallet"/>
                                <h4 className="feature-title mb-20">
                                <a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Currency Wallet</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-2.png" className="feature-icon mb-30" alt="Internal Integrition"/>
                                <h4 className="feature-title mb-20">
                                <a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Internal Integrition</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-3.png" className="feature-icon mb-30" alt="Security Storage"/>
                                <h4 className="feature-title mb-20"><a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Security Storage</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-4.png" className="feature-icon mb-30" alt="Reliable & Low Cost"/>
                                <h4 className="feature-title mb-20"><a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Reliable & Low Cost</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-5.png" className="feature-icon mb-30" alt="Insurence Protection"/>
                                <h4 className="feature-title mb-20"><a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Insurence Protection</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-xl-4 col-md-4 feature-box wow fadeInUp">
                        <div className="box-outer mb-30 transition">
                            <div className="box-inner purple frontend">
                                <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/feature-icon-6.png" className="feature-icon mb-30" alt="Experts Support"/>
                                <h4 className="feature-title mb-20"><a href="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/feature.html">Experts Support</a></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur sed do eiusmod tempor incididunt ipsum dolor sit amet, labore</p>
                            </div>
                            <div className="box-inner purple backend">
                                <p>Sed ut perspiciatis unde omnis iste eaque ipsa quae ab under natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionThree;