import React from 'react';

function SectionTwo(props) {
    return (
        <section className="works relative purple ptb-100 overflow-h" >
            <div className="container" id= 'how-it-work'>
                <div className="row d-flex flex-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 relative z-index-1">
                        <div className="section-heading text-center">
                            <h6 className="sub-title text-uppercase wow fadeInUp">What is Smart EarnerX</h6>
                            <h2 className="title wow fadeInUp">How it Works</h2>
                            <p className="wow fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 wow fadeInLeft">
                        <div className="work-content relative z-index-1">
                            <h3 className="section-sub">We’ve built a platform to buy and sell shares.</h3>
                            <div className="work-box relative">
                                <div className="work-img relative">
                                    <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/work-icon-1.png" className="relative z-index-1" alt="Decentralized Platform"/>
                                </div>
                                <div className="work-text">
                                    <h4 className="work-title">Crypto Currency Trading</h4>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                </div>
                            </div>
                            <div className="work-box relative">
                                <div className="work-img relative">
                                    <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/work-icon-2.png" className="relative z-index-1" alt="Crowd Wisdom"/>
                                </div>
                                <div className="work-text">
                                    <h4 className="work-title">Forex Trading</h4>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                </div>
                            </div>
                            <div className="work-box relative">
                                <div className="work-img relative">
                                    <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/work-icon-3.png" className="relative z-index-1" alt="Rewards MeAchanism"/>
                                </div>
                                <div className="work-text">
                                    <h4 className="work-title">Real Estate</h4>
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
                        <div className="text-center">
                            <img src="https://themes.templatescoder.com/cryptorius/html/demo/1-0/02-Light-Theme/images/work-img.png" className="relative z-index-1" alt="Works Image"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionTwo;